<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <a-form layout="vertical">
        <a-row :gutter="48">

          <a-col
            :md="5"
            :sm="24"
          >
            <!-- <a-form-item
                label="天气信息"
                prop="weatherText"
              >
                <a-input
                  v-model="queryParam.weatherText"
                  placeholder="请输入天气信息"
                  allow-clear
                />
              </a-form-item> -->
            <a-range-picker
              v-model="value1"
              style="margin-top:28px;"
              @change="handleTime"
            />
          </a-col>
          <!-- <a-col
            :md="5"
            :sm="24"
          >
            <a-form-item
              label="设备名称"
              prop="devName"
            >
              <a-input
                v-model="queryParam.devName"
                placeholder="请输入设备名称"
                allow-clear
              />
            </a-form-item>
          </a-col> -->
          <a-col
            :md="5"
            :sm="24"
          >
            <a-form-item
              label="设备名称"
              prop="devId"
            >
              <a-select
                placeholder="请选择"
                v-model="queryParam.devId"
                style="width: 100%"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                allow-clear
              >
                <a-select-option
                  v-for="(d, index) in deviceList"
                  :key="index"
                  :value="d.id"
                >{{ d.devName }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col
            :md="8"
            :sm="24"
          >
            <span class="table-page-search-submitButtons">
              <a-button
                type="primary"
                @click="handleQuery"
                style="margin-left: 8px;margin-top:28px;"
              ><a-icon type="search" />查询</a-button>
              <!-- <a-button
                style="margin-left: 8px;margin-top:28px;"
                @click="resetQuery"
              ><a-icon type="redo" />重置</a-button> -->
              <!-- <a-button
                type="primary"
                style="margin-left: 8px;margin-top:28px;"
                v-hasPermi="['gx:devicerecord:export']"
                @click="handleExport"
              >
                导出
              </a-button>
              <a-button
                type="primary"
                v-print="'#printContent'"
                style="margin-left: 8px;margin-top:28px;"
              >
                打印
              </a-button> -->
              <!-- <a
                @click="toggleAdvanced"
                style="margin-left: 8px"
              >
                {{ advanced ? '收起' : '展开' }}
                <a-icon :type="advanced ? 'up' : 'down'" />
              </a> -->
              <!-- <a-button
                  type="primary"
                  @click="$refs.createForm.handleAdd()"
                  v-hasPermi="['gx:devicetype:add']"
                  style="margin-left: 8px;margin-top:28px;"
                >
                  <a-icon type="plus" />新增
                </a-button> -->
            </span>
          </a-col>
        </a-row>
      </a-form>
      <lineChart
        :chartData="chartData"
        v-if="chartData"
      ></lineChart>
    </a-card>
  </page-header-wrapper>
</template>
<script>
import { getDevice, getDevTj } from '@/api/home'
import lineChart from '@/views/Charts/lineCharts.vue'
import moment from 'moment'
export default {
  name: 'DeviceType',
  components: {
    lineChart
  },
  data () {
    return {
      list: [],
      deviceList: [],
      value1: [],
      chartData: {},
      // 查询参数
      queryParam: {
        sortField: 'createTime',
        sortOrder: 'DESCEND',
        name: null,
        tenantId: undefined,
        devId: undefined,
        pageNum: 1,
        pageSize: 10
      }
    }
  },
  filters: {
  },
  created () {
    const end = moment().format('YYYY-MM-DD') + ' 23:59:59'
    const start = moment().subtract(1, 'day').format('YYYY-MM-DD') + ' 00:00:00'
    this.queryParam.createBeginTime = start
    this.queryParam.createEndTime = end
    this.value1 = [start, end]
    this.getDev()
    // setTimeout(() => {
    // }, 500)
  },
  methods: {
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    /** 查询所有设备信息列表 */
    getDev () {
      getDevice().then(response => {
        this.deviceList = response.data
        if (this.deviceList.length) {
          this.queryParam.devId = this.deviceList[0].id
          this.getList()
        }
        // this.total = response.data.total
      })
    },
    // 查询过车统计
    getList () {
      this.loading = true
      getDevTj(this.queryParam).then(response => {
        console.log(response, 'responseresponse')
        const res = response.data
        // let
        const query = {
          cellVoltage: [],
          chargeCurrent: [],
          dischargeCurrent: [],
          nowCarTime: []
        }
        res.forEach(v => {
          v.nowCarTime = v.nowCarTime.replace('T', ' ')
          query.nowCarTime.push(v.nowCarTime)
          if (!v.cellVoltage) {
            v.cellVoltage = 0
          }
          if (!v.chargeCurrent) {
            v.chargeCurrent = 0
          }
          if (!v.dischargeCurrent) {
            v.dischargeCurrent = 0
          }
          query.dischargeCurrent.push(v.dischargeCurrent)
          query.chargeCurrent.push(v.chargeCurrent)
          query.cellVoltage.push(v.cellVoltage)
        })
        this.chartData = query
        // this.deviceList = response.data
        // this.total = response.data.total
        this.loading = false
      })
    },
    handleTime (e) {
      this.queryParam.createBeginTime = e[0].format('YYYY-MM-DD')
      this.queryParam.createEndTime = e[1].format('YYYY-MM-DD')
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        sortField: 'createTime',
        sortOrder: 'DESCEND',
        name: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.value1 = []
      this.queryParam.devId = this.devId
      if (this.role === 'GS') {
        this.queryParam.tenantId = this.tenantIds
      }
      this.handleQuery()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    }
  }
}
</script>
