<template>
  <div class="className" />
</template>

<script>
import echarts from 'echarts' // echarts theme
import { debounce } from '@/utils/util'
require('echarts/theme/macarons')
export default {
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Object,
      default: () => { return {} }
    },
    name: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      chart: null
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler (val) {
        console.log(val, 'ddd嘟嘟嘟嘟')
        this.setOptions(val)
      }
    }
  },
  mounted () {
    this.initChart()
    if (this.autoResize) {
      this.__resizeHandler = debounce(() => {
        if (this.chart) {
          this.chart.resize()
        }
      }, 100)
      window.addEventListener('resize', this.__resizeHandler)
      setTimeout(() => {
        if (this.chart) {
          this.chart.resize()
        }
      }, 600)
    }
  },
  beforeDestroy () {
    if (!this.chart) {
      return
    }
    if (this.autoResize) {
      window.removeEventListener('resize', this.__resizeHandler)
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    setOptions ({ cellVoltage, chargeCurrent, dischargeCurrent, nowCarTime } = {}) {
      this.chart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        grid: {
          show: false,
          backgroundColor: 'transparent',
          top: '15%',
          bottom: '5%',
          right: 100,
          left: '5%'
        },
        toolbox: {
          feature: {
            saveAsImage: { show: true }
          }
        },
        legend: {
          data: ['放电电流', '充电电流', '电池电压']
        },
        xAxis: [
          {
            type: 'category',
            data: nowCarTime,
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        dataZoom: [
          {
            type: 'slider',
            show: true,
            xAxisIndex: [0],
            handleSize: 0, // 滑动条的 左右2个滑动条的大小
            height: 10, // 组件高度
            left: 550, // 左边的距离
            right: 540, // 右边的距离
            bottom: 506, // 右边的距离
            handleColor: '#ddd' // h滑动图标的颜色
          },
          {
            type: 'inside',
            start: 0,
            end: 50,
            textStyle: {
              color: '#fff'
            }
          },
          {
            show: true,
            type: 'slider',
            // y: '94%',
            bottom: '0',
            start: 0,
            end: 50,
            textStyle: {
              color: '#fff'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '电流',
            axisLabel: {
              formatter: '{value} (A)'
            }
          },
          {
            type: 'value',
            name: '电压',
            axisLabel: {
              formatter: '{value} (V)'
            }
          }
        ],
        series: [
          {
            name: '放电电流',
            type: 'line',
            yAxisIndex: 0,
            tooltip: {
              valueFormatter: function (value) {
                return value + 'A'
              }
            },
            data: dischargeCurrent,
            itemStyle: {
              normal: {
                color: 'red',
                lineStyle: {
                  color: 'red'
                }
              }
            }
          },
          {
            name: '充电电流',
            type: 'line',
            yAxisIndex: 0,
            tooltip: {
              valueFormatter: function (value) {
                return value + 'A'
              }
            },
            data: chargeCurrent,
            itemStyle: {
              normal: {
                color: 'green',
                lineStyle: {
                  color: 'green'
                }
              }
            }
          },
          {
            name: '电池电压',
            type: 'line',
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + ' V'
              }
            },
            data: cellVoltage,
            itemStyle: {
              normal: {
                color: 'blue',
                lineStyle: {
                  color: 'blue'
                }
              }
            }
          }
        ]
      })
    },
    initChart () {
      this.chart = echarts.init(this.$el, 'macarons')
      this.setOptions(this.chartData)
    }
  }
}
</script>
<style scss scoped>
.className {
  width: 100%;
  height: 75vh;
}
</style>
